import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Global, css } from '@emotion/react';
import SEO from '../components/organisms/seo';
import { media } from '../utils/media-queries';
import Close from '../components/atoms/close';
import RichText from '../components/atoms/richtext';

const VitaPage = ({ data }) => {
  const {
    _rawFirstDescription,
    _rawSecondDescription,
    _rawKeywords,
    vitaSections,
    past,
    additionalSections,
  } = data.vita.nodes[0];

  return (
    <>
      <Global
        styles={css`
          .main-wrapper {
            background: var(--b);
            color: var(--w);
          }
        `}
      />
      <SEO siteTitle="Projekte" />
      <StyledVita className="grid">
        <Close />
        <h1 className="hl">
          Vita <br />
          Erik Jan Rippmann
        </h1>
        <div className="desc">
          <div>
            <RichText blocks={_rawFirstDescription} />
          </div>
          <div>
            <RichText blocks={_rawSecondDescription} />
          </div>
        </div>
        <div className="intro hl outl-b">
          <p className="culture">
            Kultur + <br /> Wirtschaft
          </p>
          <p>
            Methode <br /> Ziele
          </p>
        </div>
        <div className="keywords hl outl-b">
          <RichText blocks={_rawKeywords} />
        </div>
      </StyledVita>
      <StyledSections>
        {vitaSections.map((vita, vitaI) => (
          <div className="grid" key={vitaI}>
            <h3 className="hl" style={{ color: vita.titleColor?.hex }}>
              {vita.title}
            </h3>
            <div className="vita-items">
              {vita.items.map((item, itemI) => (
                <div className="item" key={itemI}>
                  <p className="title">
                    <span />
                    {item.title}
                  </p>
                  <p className="desc">{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </StyledSections>
      <StyledPast>
        {past.map((pastItem, pastI) => (
          <div className="grid" key={pastI}>
            <h4 className="hl">{pastItem.when}</h4>
            <div className="past-item">
              <RichText blocks={pastItem._rawWhat} />
            </div>
          </div>
        ))}
      </StyledPast>
      <StyledAdd>
        {additionalSections.map((addItem, i) => (
          <div key={i} className="grid">
            <h5>{addItem.title}</h5>
            <div className="add-items hl">
              {addItem.items.map((item, itemI) => (
                <div
                  key={itemI}
                  className="item"
                  style={{ color: item.titleColor?.hex }}
                >
                  <RichText blocks={item._rawTitle} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </StyledAdd>
    </>
  );
};

const StyledVita = styled.section`
  padding-left: var(--sp-1-3);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  h1 {
    padding-top: var(--sp-1-3);
  }

  .desc {
    order: 2;
    text-transform: none;
    width: 80%;
    padding: var(--sp-3) 0;
    p {
      padding-bottom: var(--sp-2);
    }
  }

  .intro {
    order: 1;
  }

  .keywords {
    order: 3;
    padding-bottom: var(--sp-2);
  }

  @media ${media.XL} {
    display: grid;
    padding-left: 0;
    padding-top: calc(var(--fs-hl) * 2);

    h1 {
      padding-left: var(--sp-1-3);
      position: fixed;
      top: 0;
      z-index: 1;
    }

    .desc,
    .intro,
    .keywords {
      order: 0;
    }

    .desc {
      grid-column: 3 / 10;
      display: flex;
      padding-top: var(--sp-8);
      padding-bottom: 0;
      width: auto;
      min-width: 160%;

      p {
        padding-bottom: 0;
      }

      div {
        padding-right: var(--sp-1-3);
      }
    }

    .intro {
      grid-column: 15 / 24;
      transform: translateY(calc(var(--fs-hl) * -1.8));

      .culture {
        padding-bottom: var(--sp-5);
      }
    }

    .keywords {
      grid-column: 3 / 22;
      transform: translateY(calc(var(--fs-hl) * -1));
      padding-bottom: 0;
    }
  }
`;

const StyledSections = styled.section`
  padding-left: var(--sp-1-3);
  padding-top: 0;

  span {
    display: inline-block;
    border-radius: 50%;
    background: var(--w);
    height: 5px;
    width: 5px;
    transform: translateY(-1px);
    margin-right: 4px;
  }

  .vita-items {
    .desc {
      text-transform: none;
      padding-bottom: var(--sp-2);
      width: 90%;
    }
  }

  @media ${media.L} {
    padding-left: 0;

    .grid {
      padding-top: var(--sp-3);
      padding-bottom: var(--sp-8);
    }

    h3 {
      grid-column: 3 / 10;
      transform: translateY(calc(var(--fs-hl) / -5));
    }

    .vita-items {
      grid-column: 12 / 14;

      .item {
        display: flex;

        p {
          min-width: 20vw;
        }
      }

      .desc {
        padding-bottom: 0;
        width: auto;
      }
    }
  }
`;

const StyledPast = styled.section`
  padding-left: var(--sp-1-3);
  padding-top: var(--sp-5);

  h4 {
    padding-top: var(--sp-1);
  }

  .past-item {
    text-transform: none;
    width: 70%;
  }

  @media ${media.L} {
    padding-left: 0;
    padding-top: var(--sp-3);

    h4 {
      grid-column: 3 / 10;
      transform: translateY(-1.5rem);
      padding-top: 0;
    }

    .past-item {
      grid-column: 12 / 15;
      width: auto;
    }
  }
`;

const StyledAdd = styled.section`
  padding-left: var(--sp-1-3);

  h5 {
    padding-top: var(--sp-5);
  }

  @media ${media.L} {
    padding-left: 0;
    padding-top: var(--sp-5);

    h5 {
      grid-column: 3 / 10;
      transform: translateY(1.5rem);
      padding-top: 0;
    }

    .add-items {
      grid-column: 12 / 23;
      padding-bottom: var(--sp-5);
    }
  }
`;

export const query = graphql`
  query Vita {
    vita: allSanityVita {
      nodes {
        _rawFirstDescription
        _rawSecondDescription
        _rawKeywords
        vitaSections {
          items {
            title
            description
          }
          title
          titleColor {
            hex
          }
        }
        past {
          when
          _rawWhat
        }
        additionalSections {
          title
          items {
            _rawTitle
            titleColor {
              hex
            }
          }
        }
      }
    }
  }
`;

VitaPage.propTypes = {
  data: PropTypes.object,
};

export default VitaPage;
